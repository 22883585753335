import PropTypes from 'prop-types';

// Bild, dass mit dem **GatsbyImageSharpFluid_withWebp_tracedSVG** Fragment erzeugt wurde
export const fluidImage = {
    childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
            tracedSVG: PropTypes.string,
            aspectRatio: PropTypes.number,
            src: PropTypes.string,
            srcSet: PropTypes.string,
            srcWebp: PropTypes.string,
            srcSetWebp: PropTypes.string,
            sizes: PropTypes.string,
        }),
    }),
};

export const fluidImageType = PropTypes.shape(fluidImage);

/**
 * Erzeugt ein Schema für "all*" Queries
 * @param {PropType} type PropType des node Feldes
 * @example
 * nodesOf(PropTypes.string);
 *
 * ->
 *
 * PropTypes.shape({
 *    edges: PropTypes.arrayOf(
 *         PropTypes.shape({
 *             node: PropTypes.string,
 *         })
 *     ),
 * });
 */
export function nodesOf(type) {
    return PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                node: type,
            })
        ),
    });
}

// JSX Children
export const childrenType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
]);
